import { useState } from 'react'
import logoHeader from '../assets/logo-header.png'
import menuIcon from '../assets/menu-icon.png'

import { Text } from './text'
import { Title } from './title'

export const Header = () => {

  const [route, setRoute] = useState(window.location.hash.replace('#', ''))
  const [showMenu, setShowMenu] = useState(false)

  const menus = [
    { key: '', name: 'Início' },
    { key: 'about', name: 'Sobre' },
    { key: 'services', name: 'Serviços' },
    { key: 'depositions', name: 'Depoimentos' },
    { key: 'contact', name: 'Contato' },
  ]

  return (
    <>
      <div className="bg-white px-6 lg:px-20 xl:px-40 py-4 border-b-[1px] border-b-[#E4E4E4] flex justify-between items-center fixed z-40 w-full">
        <img src={logoHeader} alt="logo in move colorido" className="w-44" />
        <img src={menuIcon} alt="logo in move colorido" className="w-8 h-8 lg:hidden cursor-pointer" onClick={() => setShowMenu(true)} />
        <div className="hidden lg:flex items-center gap-8">
          {
            menus.map((menu, index) => (
              <div className="cursor-pointer" key={index} onClick={() => {
                window.location.href = `#${menu.key}`
                setRoute(menu.key)
              }}>
                <Text textColor={route === menu.key ? 'text-primary' : 'text-[#767676]'}>{menu.name}</Text>
              </div>
            ))
          }
        </div>

      </div>
      <div data-show={showMenu} className="fixed w-full h-screen bg-white z-50 justify-center items-center data-[show=true]:flex hidden">
        <div className="flex flex-col items-center gap-16">
          {
            menus.map((menu, index) => (
              <div className="cursor-pointer" key={index} onClick={() => {
                window.location.href = `#${menu.key}`
                setRoute(menu.key)
                setShowMenu(false)
              }}>
                <Title>{menu.name}</Title>
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
}