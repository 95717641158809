import { Text } from './text'

type CardCarouselType = {
  text: string
}

export const CardCarousel = ({text}: CardCarouselType) => {
  return (
    <div className="bg-white p-8 rounded-xl shadow-lg shadow-gray-300 xl:p-14">
      <Text>{text}</Text>
    </div>
  )
}