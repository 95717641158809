import { Text } from './text'

import logoWhite from '../assets/logo-white.png'

export const Footer = () => {
  return (
    <div className="bg-primary py-16 px-6 flex flex-col gap-4 lg:px-20 xl:px-40 lg:flex-row lg:items-center lg:py-10">
      <img src={logoWhite} alt="logo da in move" className='w-36' />
      <div className="flex flex-col gap-1">
        <Text textColor="text-background">©2024 In Move</Text>
        <Text textColor="text-background">Todos os direitos reservados.</Text>
      </div>
    </div>
  )
}