import { useEffect } from "react"
import { Button } from "./components/button"
import { Card } from "./components/card"
import { CardCarousel } from "./components/card-carousel"
import { Footer } from "./components/footer"
import { Header } from "./components/header"
import { LineGradient } from "./components/line-gradient"
import { Text } from "./components/text"
import { Title } from "./components/title"

import aboutImage from './assets/about.png'
import arrowIcon from './assets/arrow-icon.png'
import mainImage from './assets/main.png'
import mapIcon from './assets/map-icon.png'
import phoneIcon from './assets/phone-icon.png'

import Aos from "aos"
import "aos/dist/aos.css"
import { openMap, openPhone, sendMessageToWhats } from "./utils/functions"

export const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500
    });
    Aos.refresh();
  }, []);

  const services: any = [
    {
      title: 'Fisioterapia',
      text: 'Por meio de exercícios personalizados e recursos terapêuticos, buscamos proporcionar uma fisioterapia ortopédica eficaz, restaurando  a qualidade de vida dos nossos pacientes.'
    },
    {
      title: 'Drenagem Linfática',
      text: 'Este tratamento é indicado para reduzir a retenção de líquidos e inchaço. É especialmente útil durante a recuperação pós-operatória.'
    },
    {
      title: 'Massagens',
      text: 'Desfrute de uma experiência de massagem única, com opções terapêuticas, relaxantes e com pedras quentes, para um bem-estar completo.'
    },
  ]

  const depositions = [
    {
      text: 'Eu sou cliente desde o ínicio e não troco por nada! Certamente minha disposição e ansiedade mudou muito depois que comecei a fazer as massagem. Profissional incrível e qualificada.'
    },
    {
      text: 'Muito boa a massagem! Para o período de exercícios intensos que tenho passado faz toda a diferença! Musculatura bem melhor! Obrigado pela atenção.'
    },
  ]

  return (
    <div className="overflow-hidden">
      <Header />

      <div id="" className="bg-background w-full flex justify-between flex-col items-center">
        <div className="w-full flex justify-between flex-col pb-20 lg:flex-row-reverse xl:flex-row-reverse xl:px-20 xl:py-20 xl:pb-60 lg:mt-24 mt-14 xl:mt-10">
          <div data-aos="zoom-in-left"  className="lg:pr-0 xl:pr-20">
            <div className="bg-[#11A89D25] w-[350px] h-[240px] sm:w-[750px] sm:h-[300px] md:w-[400px] xl:w-[550px] xl:h-[375px] rounded-xl ml-20 mt-10" />
            <img src={mainImage} alt="imagem de uma mulher fazendo massagem" className="lg:absolute lg:right-10 xl:right-40 xl:top-28 sm:-mt-64 -mt-48 -ml-10 xl:ml-0 xl:mt-0 rounded-xl" />
          </div>
          <div data-aos="fade-right" className="w-full flex flex-col justify-center items-center gap-6 text-center mt-8 lg:text-start lg:items-start lg:mt-20 lg:pl-20">
            <div className="lg:w-6/12 xl:w-7/12">
              <Title>Cuidar de você não é gasto, <span className="text-secondary">é investimento!</span></Title>
            </div>
            <Text>Uma clínica exclusiva em Guarulhos, <br />especializada em fisioterapia e massoterapia.</Text>
            <Button onClick={() => sendMessageToWhats('Olá, tudo bem?%0DGostaria de agendar uma sessão, quais são os próximos passos?')}>Agendar sessão agora</Button>
          </div>
        </div>

        <img src={arrowIcon} alt="icon de seta para baixo" className="w-12 mb-10 animate-bounce" />
      </div>
      
      <LineGradient position="end" />

      <div id="about" className="bg-white w-full flex flex-col justify-between gap-8 pb-20 items-center lg:flex-row lg:pr-10 xl:pr-36 mt-10 lg:mt-20">
        <div data-aos="fade-right">
          <div className="bg-[#11A89D25] w-[300px] h-[240px] sm:w-[450px] xl:w-[550px] xl:h-[375px] rounded-e-xl" />
          <img src={aboutImage} alt="imagem de uma mulher fazendo massagem" className="-mt-48 -ml-10 xl:-ml-10 xl:-mt-80 rounded-xl xl:w-[850px]" />
        </div>
        <div data-aos="fade-left" className="px-6 flex flex-col gap-4 xl:w-6/12 xl:justify-center">
          <Title>Sobre nós</Title>
          <Text>
            A In Move foi fundada com o objetivo de proporcionar serviços de fisioterapia de alta qualidade, adotando uma abordagem individualizada e personalizada para cada paciente. Nosso compromisso é oferecer um tratamento não apenas eficaz, mas também dinâmico, que se adapte às necessidades específicas de cada pessoa.
            <br /><br />
            Além dos nossos serviços de fisioterapia, oferecemos também massoterapia, que complementa e potencializa o tratamento fisioterapêutico, além de promover a manutenção da qualidade de vida. Nosso foco está em proporcionar uma experiência completa e satisfatória, com atenção meticulosa às necessidades de cada cliente e um compromisso genuíno com o seu bem-estar.
            <br /><br />
            Estamos dedicados a construir relações duradouras com nossos clientes, baseadas na confiança e na entrega de resultados consistentes. Na In Move, cada paciente é tratado com a máxima consideração e cuidado, refletindo nossa missão de ser um parceiro confiável na jornada para a saúde e a recuperação.
          </Text>
        </div>
      </div>
      
      <LineGradient position="start" color="secondary" />

      <div id="services" className="bg-background w-full flex flex-col gap-4 py-20 px-6 lg:px-20 xl:px-40 lg:text-center lg:items-center">
        <div data-aos="fade-down">
          <Title>Serviços</Title>
        </div>
        <div data-aos="fade-down" className="lg:w-6/12 xl:w-4/12">
          <Text>
            Com experiência no mercado, a <b className="text-secondary">In Move</b> já conquistou muitos clientes com seus tratamentos exclusivos e personalizados
          </Text>
        </div>
        
        <div data-aos="fade-up" className="flex flex-col gap-6 lg:flex-row mb-6 mt-4">
          {
            services.map((s: any) => <Card title={s.title} text={s.text} />)
          }
        </div>

        <div data-aos="fade-up" className="w-full flex justify-center items-center">
          <Button type="secondary" onClick={() => sendMessageToWhats('Olá, tudo bem?%0DMe interessei pelos serviços e gostaria de saber mais informações.')}>Mais informações</Button>
        </div>
      </div>
      
      <LineGradient position="end" color="secondary" />

      <div id="depositions" className="bg-white w-full flex flex-col justify-between gap-8 py-20 lg:px-20 xl:px-40 px-6">
        <div data-aos="fade-down" className="lg:flex lg:justify-center lg:items-center lg:text-center">
          <div>
            <Title>Depoimentos de quem já<br /> passou por aqui</Title>
          </div>
        </div>

        <div data-aos="fade-up" className="flex flex-col gap-4 lg:flex-row lg:gap-8">
          {depositions.map(d => <CardCarousel text={d.text} />)}
        </div>
      </div>
      
      <LineGradient />

      <div id="contact" className="bg-background w-full flex flex-col justify-between lg:flex-row gap-16 py-20 px-6 lg:px-20 xl:px-40 lg:items-center">
        <div data-aos="fade-left" className="flex flex-col gap-6 lg:w-6/12 xl:w-4/12">
          <Title>Entre em contato conosco!</Title>
          <Text>Entre em contato com a In Move, queremos tirar suas dúvidas, ouvir suas críticas e sugestões.</Text>
          <Button onClick={() => sendMessageToWhats('Olá, tudo bem?%0DEstou entrando em contato pois gostaria de saber mais sobre a empresa')}>Entrar em contato</Button>
        </div>

        <div data-aos="fade-right" className="flex flex-col gap-6">
          <div className="flex gap-3 items-center" onClick={() => openPhone()}>
            <img src={phoneIcon} alt="icone" />
            <Text>11 94557-1917</Text>
          </div>
          <div className="flex gap-3 items-center" onClick={() => openMap()}>
            <img src={mapIcon} alt="icone" />
            <Text>Av. Bom Clima, Guarulhos</Text>
          </div>
          {/* <div className="flex gap-3 items-center" onClick={() => openMail()}>
            <img src={mailIcon} alt="icone" />
            <Text>contato@fisioinmove.com</Text>
          </div> */}
        </div>
      </div>

      <Footer />
    </div>
  )
}
