type ButtonType = {
  children: any
  type?: 'primary' | 'secondary'
  onClick?: () => void
}

export const Button = ({type = 'primary', onClick, children}: ButtonType) => {

  const getColor = () => {
    switch (type) {
      case 'primary':
        return 'text-background bg-primary'
      case 'secondary':
        return 'text-background bg-secondary'
    }
  }
  
  return (
    <div className={`w-max py-4 px-8 rounded-xl cursor-pointer hover:brightness-95 transition-colors ${getColor()}`} onClick={onClick}>
      <p className="font-dm-sans font-medium text-base">{children}</p>
    </div>
  )
}