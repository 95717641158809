import drenagem from '../assets/drenagem.png'
import fisio from '../assets/fisioterapia.png'
import massagem from '../assets/massagem.png'
import { Text } from "./text"
import { Title } from "./title"

type CardType = {
  title: 'Fisioterapia' | 'Drenagem Linfática' | 'Massagens'
  text: string
}

export const Card = ({title, text}: CardType) => {
  const image = {
    Fisioterapia: fisio,
    'Drenagem Linfática': drenagem,
    Massagens: massagem
  }
  
  return (
    <div className="bg-background rounded-t-xl text-center flex flex-col justify-center items-center gap-4 shadow-xl shadow-gray-300 border-b-4 border-b-secondary py-14 px-8 lg:w-4/12">
      <img src={image[title]} className="w-20 h-20" alt="icon" />
      <Title size="text-2xl">{title}</Title>
      <Text>{text}</Text>
    </div>
  )
}